import * as React from "react"
import Layout from "../components/layout"
import '../style.css'
import Star from "../components/star";
import { Link } from "gatsby"

const App = () => {
  return (
      <Layout>
        <main>
          <div className="index-text-container">
            <h1>Neil Gehrels Swift Observatory</h1>
            <Star/>
            <script src='https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_CHTML' async></script>
            
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', border:'3px solid white', borderRadius:'15px', margin:'2rem', padding:'1rem'}}>
              <Link href='/swift20' target="_blank" rel="norefferer">
              <h2>Swift's 20th Anniversary is here! Click to check it out!</h2>
              </Link>
              {/* <p style={{maxWidth:'500px'}} className='education-item-buttons' href="/swift20">
                <Link href='/swift20'>Learn More!</Link>
              </p> */}
            </div>
            
            <p className="index-blurb">
              Welcome to the Neil Gehrels Swift Observatory mission Education and Public Outreach (E/PO) page. Here you will find cool science, mathematics, and technology learning experiences. Our goal is to use the observations and scientific discoveries of the Swift mission to improve the understanding and utilization of science and mathematics concepts for grades 7-12. For the lastest news about the Gehrels Swift mission head to <a href="https://swift.gsfc.nasa.gov" target='_blank' rel="noreferrer">swift.gsfc.nasa.gov</a>
            </p>
          </div>
        </main>
      </Layout>
  )
}

export default App
